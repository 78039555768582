:root {
    --black: #000;
    --bright-200: #d1eaf2;
    --gray-50: #f9fafb;
    --gray-100: #f3f4f6;
    --gray-200: #e5e7eb;
    --gray-300: #d1d5db;
    --gray-400: #9ca3af;
    --gray-500: #6b7280;
    --gray-700: #374151;
    --gray-900: #111928;
    --peppermint-50: #eaf8f8;
    --peppermint-200: #d2f2f0;
    --peppermint-300: #b8e2e2;
    --peppermint-400: #569f9f;
    --peppermint-500: #1b6b6b;
    --peppermint-700: #004d4d;
    --peppermint-900: #092526;
    --orange-200: #fcdebd;
    --orange-400: #ff992e;
    --orange-600: #d06c01;
    --orange-800: #904e08;
    --red-50: #fcf0f0;
    --red-600: #a41508;
    --red-700: #831107;
    --scale: #fafaf5;
    --scale-transparent-90: rgba(250, 250, 245, .9);
    --transparent: transparent;
    --transparent-hover: rgba(255, 255, 255, .1);
    --white: #fff;
    --white-transparent-30: rgba(255, 255, 255, .3);
    --white-transparent-40: rgba(255, 255, 255, .4);
    --white-transparent-70: rgba(255, 255, 255, .7);
    --yellow: #f1e000;
    --yellow-light: #f6ee73;

    /* fonts (DO NOT USE! USE FONT MIXINS INSTEAD -> mixins/_mixins.css) */
    --berlingske: "Berlingske Serif", "Georgia", serif;
    --hanken: "Hanken Grotesk", Tahoma, sans-serif;
    --tahoma: Tahoma, sans-serif;
    --root-font-size: calc(16px + ( 20 - 16 ) * (( 100vw - 320px ) / ( 1200 - 320 ) ));
    --body-font-size: calc(14px + ( 16 - 14 ) * (( 100vw - 320px ) / ( 1200 - 320 ) ));
    --module-border-radius: 8px;
    --module-padding-top-bottom: 2.5em;
    --module-padding-left-right: 15px;
    --module-padding: var(--module-padding-top-bottom) var(--module-padding-left-right);
    --module-max-width: 1200px;
    --z-index-negative: -1;
    --z-index-0: 0;
    --z-index-1: 10;
    --z-index-2: 20;
    --z-index-10: 100;
    --z-index-11: 110;
    --image-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
}

/*
 * Some font-sizes are calculated by the CSS calc() function to enable the use of fluid typography.
 * Example --h1-font-size:
 * The smallest font size is 40px @320px viewport width or lower.
 * The largest font size is 53px @1200px viewport width or larger.
 * The min viewport width is 320px.
 * The max viewport width is 1200px.
 * The values can be changed at your needs.
 * The notation of the smallest and largest font size as well as the min and max width of the viewport
 * prevents the font from getting to small or to big on smaller or larger screens.
 *
 * --var: calc( min-size + ( max-size - min-size ) * ( ( 100vw - min-width ) / ( max-width - min-width ) ));
 *
 */
