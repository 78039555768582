/**
 * article-list-print
 */

.article-head__subheadline {
    font-size: 18px;
}

.article-head__intro {
    font-size: 14px;
    line-height: 1.4em;
}
