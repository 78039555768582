.header__toggle,
.header__toggle:link,
.header__toggle:visited,
.header__toggle:hover,
.header__toggle:active {
    display: none;
}

.header {
    overflow: hidden;
    padding-top: 0;
    position: relative;
}

.header__wrapper-logo {
    background: none;
    left: 0;
    position: relative;
}

.header__logo {
    position: relative;
}

.header__logo-img {
    display: block;
    height: 100%;
    width: 100%;
}

.header--minimal {
    background: none;
}
