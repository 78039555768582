html {
    background-color: #fff;
    margin-left: 1.6cm;
}

body {
    color: #000;
    font-size: 12px;
    line-height: 1.5em;
}

h3,
h4,
h5,
h6,
.p-h3,
.p-h4,
.p-h5,
.p-h6 {
    font-size: 16px;
}

h1,
.p-h1 {
    font-size: 21px;
}

h2,
.p-h2 {
    font-size: 18px;
}

fieldset {
    border: 0;
    margin: 0 0 2.5em; /* 40px@320 */
    padding: 0;
    width: 100%;
}

.page .page__module-wrapper-inner {
    padding: 1.5em .625rem;
}

.breadcrumb__list.page__module-wrapper-inner {
    padding-bottom: .4em;
    padding-top: .4em;
}

.article-head .page__module-wrapper-inner {
    padding-top: 1em;
}

.page__footer {
    display: none;
}
