.accordion__label {
    background: 0;
    border: 1px solid var(--gray-400);
    border-bottom-width: 0;
    margin-bottom: 0;
}

.accordion__icon {
    display: none;
}

.accordion__head {
    color: #000;
    margin: 0;
    padding: 10px;
}

.accordion__body {
    background: 0;
    border: 1px solid var(--gray-400);
    margin: 0 0 .4em;
}

.accordion--initialized .accordion__body {
    display: block;
}

.accordion__text {
    padding: 10px;
}
