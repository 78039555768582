.icon-list__body {
    background: none;
    padding: .4em 1em;
}

.icon-list__list {
    background: var(--white);
    padding: .51em 1em .5em;
}

.icon-list__item-text {
    margin-bottom: .5em;
}
