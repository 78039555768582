/**
 * hero-print
 */

.hero__background {
    display: none;
}

.hero .hero__wrapper {
    padding-bottom: 1%;
    padding-top: 1%;
}

.hero__body {
    background: #fff;
    display: block;
    height: auto;
    position: relative;
    width: 50%;
}

.hero .image__picture {
    height: 40%;
}
